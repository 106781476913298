/***************************************************
  Media queries for responsive design
****************************************************/

@import "breakpoint-max";

@media (min-width: $media-breakpoint-md) {
  @import "breakpoints/md/default-menu";
  @import "breakpoints/md/mega-menu";
  @import "breakpoints/md/menu-icons";
}

@media (min-width: $media-breakpoint-lg) {
  @import "breakpoints/lg/default-menu";
  @import "breakpoints/lg/header-sticky";
  @import "breakpoints/lg/header-transparent";
  @import "breakpoints/lg/hover-transitions";
  @import "breakpoints/lg/submenu";
  @import "breakpoints/lg/submenu-transitions";
  @import "breakpoints/lg/mega-menu";
}
